import { FETCH_CONTRACT_STATES } from '../../constants/ActionTypes';

const states = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_STATES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default states;
