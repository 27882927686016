import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProcessingCheckBox from './ProcessingCheckBox';

const Contract = ({
  classes,
  metadata,
  editor,
  onProcessingConsentChange,
  onSubmit,
  onReset
}) => (
  <div className={classes.contract}>
    <Typography className={classes.title} variant='h4'>
      {
        (_(metadata.placeholdersValues || []).find({ name: 'title' }) || {})
          .value
      }
    </Typography>
    <Typography variant='body1'>
      {
        (_(metadata.placeholdersValues || []).find({ name: 'header' }) || {})
          .value
      }
    </Typography>

    {(metadata.processingPlaceholdersValues || []).map(ppv => (
      <ProcessingCheckBox
        key={ppv.name}
        processingPlaceholderValue={ppv}
        processingCheckBoxState={editor[ppv.name]}
        onChange={onProcessingConsentChange}
      />
    ))}

    <Typography variant='body1'>
      {
        (_(metadata.placeholdersValues || []).find({ name: 'footer' }) || {})
          .value
      }
    </Typography>
    <Grid
      className={classes.actionsPanel}
      spacing={16}
      container
      justify='center'
      alignItems='center'>
      <Grid item>
        <Button onClick={() => onReset()}>Reset</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => onSubmit(editor)} color='primary'>
          Update
        </Button>
      </Grid>
    </Grid>
  </div>
);

Contract.propTypes = {
  metadata: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const StyledContract = withStyles({
  contract: { margin: '0 auto', padding: 16, maxWidth: 800 },
  title: { marginBottom: 16 },
  actionsPanel: { marginTop: 16 },
  processingCheckBox: { marginTop: 8, marginBottom: 8 }
})(Contract);

export default StyledContract;
