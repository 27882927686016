import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Button from '@material-ui/core/Button';
import './LoginPage.css';
import { requestEmailLink, setEmail } from '../../actions/LoginActions';
import { CircularProgress, Typography, Grid } from '@material-ui/core';

const LoginPage = ({
  email,
  validEmail,
  loginRequested,
  requestRunning,
  requestError,
  setEmail,
  requestEmailLink
}) => (
  <>
    <Typography variant='h3' style={{ textAlign: 'center' }}>
      Consent Portal
    </Typography>

    <form id='enter-form'>
      <Grid container spacing={8} alignItems='flex-end'>
        <Grid item>
          <EmailIcon color='secondary' />
        </Grid>
        <Grid item>
          <TextField
            label='Email'
            placeholder='you@domain.com'
            value={email || ''}
            onChange={event => setEmail(event.target.value)}
          />
        </Grid>
      </Grid>
      <div style={{ margin: 20 }}>
        {!loginRequested ? (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loginRequested || requestRunning || !validEmail}
            onClick={() =>
              requestEmailLink({
                email,
                lang: (navigator.language || navigator.userLanguage).split(
                  '-'
                )[0]
              })
            }>
            Login
          </Button>
        ) : null}
        {requestRunning ? <CircularProgress style={{ margin: 20 }} /> : null}
        {requestError ? (
          <Typography variant='body1' color='error'>
            An error occured
          </Typography>
        ) : null}
        {loginRequested && !requestRunning && !requestError ? (
          <Typography variant='body1'>
            Follow the link sent to your email
          </Typography>
        ) : null}
      </div>
    </form>
  </>
);

const mapStateToProps = (state, props) => ({
  email: state.login.email,
  loginRequested: state.login.loginRequested,
  requestRunning: state.login.requestRunning,
  requestError: state.login.requestError,
  validEmail: state.login.validEmail
});

const LoginPageContainer = connect(
  mapStateToProps,
  { requestEmailLink, setEmail }
)(LoginPage);

export default LoginPageContainer;
