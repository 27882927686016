export const REQUEST_LOGIN_SET_EMAIL = 'REQUEST_LOGIN_SET_EMAIL';
export const REQUEST_LOGIN_COMPLETED = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_RUNNING = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_ERROR = 'REQUEST_LOGIN_ERROR';

export const CONTRACT_LOADING_START = 'CONTRACT_LOADING_START';
export const CONTRACT_LOADING_COMPLETED = 'CONTRACT_LOADING_COMPLETED';
export const CONTRACT_LOADING_ERROR = 'CONTRACT_LOADING_ERROR';

export const FETCH_CONTRACT_METADATA = 'FETCH_CONTRACT_METADATA';
export const FETCH_CONTRACT_STATES = 'FETCH_CONTRACT_STATES';
export const SUBMIT_CONTRACT_EVENTS_STARTED = 'SUBMIT_CONTRACT_EVENTS_STARTED';
export const SUBMIT_CONTRACT_EVENTS_COMPLETED =
  'SUBMIT_CONTRACT_EVENTS_COMPLETED';
export const SUBMIT_CONTRACT_EVENTS_ERROR = 'SUBMIT_CONTRACT_EVENTS_ERROR';
export const CHANGE_PROCESSING_CONSENT = 'CHANGE_PROCESSING_CONSENT';
