import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControlLabel, Checkbox, withStyles } from '@material-ui/core';

const CONSENT_DENIED = 'CONSENT_DENIED';
const CONSENT_GIVEN = 'CONSENT_GIVEN';

function _ProcessingCheckBox({
  classes,
  processingPlaceholderValue,
  processingCheckBoxState = {},
  onChange = () => null
}) {
  let description = _.get(
    processingPlaceholderValue,
    'processingDisclaimer.description',
    '!ERROR'
  );
  return (
    <FormControlLabel
      className={classes.processingCheckBox}
      control={
        <Checkbox
          checked={_.get(processingCheckBoxState, 'consent') === CONSENT_GIVEN}
          onChange={(event, checked) =>
            onChange({
              processingPlaceholderName: processingPlaceholderValue.name,
              consent: checked ? CONSENT_GIVEN : CONSENT_DENIED
            })
          }
          value={processingPlaceholderValue.processingPlaceholderName}
        />
      }
      label={description}
    />
  );
}

_ProcessingCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
  processingPlaceholderValue: PropTypes.object.isRequired,
  processingCheckBoxState: PropTypes.object,
  onChange: PropTypes.func
};

const ProcessingCheckBox = withStyles({
  processingCheckBox: { marginTop: 8, marginBottom: 8 }
})(_ProcessingCheckBox);

export default ProcessingCheckBox;
