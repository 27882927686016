import {
  REQUEST_LOGIN_SET_EMAIL,
  REQUEST_LOGIN_ERROR,
  REQUEST_LOGIN_COMPLETED,
  REQUEST_LOGIN_RUNNING
} from '../../constants/ActionTypes';

const emailRegex = /.+@.+/;

export default (
  state = {
    email: null,
    validEmail: false,
    loginRequested: false,
    requestRunning: false,
    requestError: false
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LOGIN_SET_EMAIL:
      let { email = '' } = action.payload;
      return {
        ...state,
        email,
        validEmail: emailRegex.test(email)
      };
    case REQUEST_LOGIN_ERROR:
      return {
        ...state,
        requestError: true,
        loginRequested: false,
        requestRunning: false
      };
    case REQUEST_LOGIN_COMPLETED:
      return {
        ...state,
        requestError: false,
        loginRequested: true,
        requestRunning: false
      };
    case REQUEST_LOGIN_RUNNING:
      return {
        ...state,
        requestError: false,
        loginRequested: true,
        requestRunning: true
      };
    default:
      return state;
  }
};
