import {
  CHANGE_PROCESSING_CONSENT,
  FETCH_CONTRACT_STATES
} from '../../constants/ActionTypes';
import _ from 'lodash';

const editor = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PROCESSING_CONSENT:
      return {
        ...state,
        [action.payload.processingPlaceholderName]: action.payload
      };
    case FETCH_CONTRACT_STATES:
      return _(action.payload.contractStates)
        .map(cs => ({
          processingPlaceholderName: cs.name,
          consent: cs.state.consentState
        }))
        .keyBy('processingPlaceholderName')
        .value();
    default:
      return state;
  }
};

export default editor;
