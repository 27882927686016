import React from 'react';
import Contract from './Contract';
import { connect } from 'react-redux';
import {
  loadContract,
  submitContractEvents,
  changeProcessingConsent
} from '../../actions/ContractActions';
import { CircularProgress, Typography } from '@material-ui/core';

class ContractPage extends React.Component {
  componentDidMount() {
    let { token } = this.props;
    this.props.loadContract(token);
  }

  render() {
    let {
      metadata,
      editor,
      error,
      token,
      loading,
      submitContractEvents,
      changeProcessingConsent
    } = this.props;
    if (error) {
      return (
        <Typography
          style={{
            position: 'fixed',
            right: '50%',
            top: '50%',
            textAlign: 'center'
          }}
          variant='h6'>
          An error occured, please retry
        </Typography>
      );
    }

    if (loading) {
      return (
        <CircularProgress
          style={{ position: 'fixed', right: '50%', top: '50%' }}
        />
      );
    }

    return (
      <Contract
        metadata={metadata}
        editor={editor}
        onSubmit={editor => {
          submitContractEvents(Object.values(editor), token);
        }}
        onProcessingConsentChange={nameAndConsent =>
          changeProcessingConsent(nameAndConsent)
        }
        onReset={() => this.props.loadContract(token)}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  token: props.match.params.token,
  metadata: state.contract.metadata,
  editor: state.contract.editor,
  error: state.contract.load.error,
  loading: state.contract.load.running
});

export default connect(
  mapStateToProps,
  { loadContract, submitContractEvents, changeProcessingConsent }
)(ContractPage);
