import {
  FETCH_CONTRACT_METADATA,
  FETCH_CONTRACT_STATES,
  SUBMIT_CONTRACT_EVENTS_STARTED,
  SUBMIT_CONTRACT_EVENTS_COMPLETED,
  CONTRACT_LOADING_START,
  CONTRACT_LOADING_COMPLETED,
  CONTRACT_LOADING_ERROR,
  SUBMIT_CONTRACT_EVENTS_ERROR,
  CHANGE_PROCESSING_CONSENT
} from '../constants/ActionTypes';

export const fetchContractMetadata = token => dispatch => {
  return fetch(`/api/portal/v1/contracts/views/metadata`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      if (response.ok) {
        return response;
      }
      throw new Error('Response error: ' + response.statusText);
    })
    .then(response => response.json())
    .then(data => dispatch({ type: FETCH_CONTRACT_METADATA, payload: data }));
};

export const fetchContractStates = token => dispatch => {
  return fetch(`/api/portal/v1/contracts/states`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      if (response.ok) {
        return response;
      }
      throw new Error('Response error: ' + response.statusText);
    })
    .then(response => response.json())
    .then(data => dispatch({ type: FETCH_CONTRACT_STATES, payload: data }));
};

export const submitContractEvents = (events, token) => dispatch => {
  dispatch({ type: SUBMIT_CONTRACT_EVENTS_STARTED });
  return fetch(`/api/portal/v1/contracts/events`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ events })
  })
    .then(response => {
      if (response.ok) {
        return fetchContractStates(token)(dispatch);
      }
      throw new Error('Response error: ' + response.statusText);
    })
    .then(() => dispatch({ type: SUBMIT_CONTRACT_EVENTS_COMPLETED }))
    .catch(err => dispatch({ type: SUBMIT_CONTRACT_EVENTS_ERROR }));
};

export const loadContract = token => (dispatch, getState) => {
  dispatch({ type: CONTRACT_LOADING_START });
  Promise.all([
    fetchContractMetadata(token)(dispatch),
    fetchContractStates(token)(dispatch)
  ])
    .then(data => dispatch({ type: CONTRACT_LOADING_COMPLETED }))
    .catch(err => dispatch({ type: CONTRACT_LOADING_ERROR }));
};

export const changeProcessingConsent = ({
  processingPlaceholderName,
  consent
}) => dispatch => {
  dispatch({
    type: CHANGE_PROCESSING_CONSENT,
    payload: { processingPlaceholderName, consent }
  });
};
