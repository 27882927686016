import {
  REQUEST_LOGIN_RUNNING,
  REQUEST_LOGIN_COMPLETED,
  REQUEST_LOGIN_ERROR,
  REQUEST_LOGIN_SET_EMAIL
} from '../constants/ActionTypes';

export const setEmail = email => dispatch =>
  dispatch({ type: REQUEST_LOGIN_SET_EMAIL, payload: { email } });

export const requestEmailLink = ({ email, lang }) => (dispatch, getState) => {
  dispatch({
    type: REQUEST_LOGIN_RUNNING
  });
  let params = {
    email,
    lang
  };
  return fetch(`/api/portal/v1/customerLogin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
    .then(response => {
      if (response.ok) {
        dispatch({ type: REQUEST_LOGIN_COMPLETED });
        return;
      }
      throw new Error('Response status: ' + response.statusText);
    })
    .catch(error => dispatch({ type: REQUEST_LOGIN_ERROR }));
};
