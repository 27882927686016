import { FETCH_CONTRACT_METADATA } from '../../constants/ActionTypes';

const metadata = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTRACT_METADATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default metadata;
