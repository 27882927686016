import {
  CONTRACT_LOADING_COMPLETED,
  CONTRACT_LOADING_ERROR,
  CONTRACT_LOADING_START,
  SUBMIT_CONTRACT_EVENTS_STARTED,
  SUBMIT_CONTRACT_EVENTS_COMPLETED,
  SUBMIT_CONTRACT_EVENTS_ERROR
} from '../../constants/ActionTypes';

const load = (state = { running: true, error: false }, action) => {
  switch (action.type) {
    case CONTRACT_LOADING_START:
    case SUBMIT_CONTRACT_EVENTS_STARTED:
      return { ...state, running: true };
    case CONTRACT_LOADING_COMPLETED:
    case SUBMIT_CONTRACT_EVENTS_COMPLETED:
      return { ...state, running: false };
    case CONTRACT_LOADING_ERROR:
    case SUBMIT_CONTRACT_EVENTS_ERROR:
      return { ...state, running: false, error: true };
    default:
      return state;
  }
};

export default load;
